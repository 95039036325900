.wysiwyg-content {
  word-break: break-word;
}
.wysiwyg-content h1,
.wysiwyg-content h2,
.wysiwyg-content h3,
.wysiwyg-content h4,
.wysiwyg-content h5,
.wysiwyg-content h6 {
  margin: 0 0 20px 0;
  padding: 0;
  counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}

.wysiwyg-content a {
  text-decoration: underline;
}

.wysiwyg-content p {
  padding-bottom: 16px;
}

.wysiwyg-content h1 {
  font-size: 2em;
}
.wysiwyg-content h2 {
  font-size: 1.5em;
}
.wysiwyg-content h3 {
  font-size: 1.17em;
}
.wysiwyg-content h4 {
  font-size: 1em;
}
.wysiwyg-content h5 {
  font-size: 0.83em;
}
.wysiwyg-content h6 {
  font-size: 0.67em;
}

.wysiwyg-content .ql-align-center {
  text-align: center;
}

.wysiwyg-content .ql-align-justify {
  text-align: justify;
}

.wysiwyg-content .ql-align-right {
  text-align: right;
}

.wysiwyg-content blockquote {
  border-left: 4px solid #ccc;
  margin-bottom: 5px;
  margin-top: 5px;
  padding-left: 16px;
}

.wysiwyg-content ol > li,
.wysiwyg-content ul > li {
  list-style-type: none;
}
.wysiwyg-content ul > li::before {
  content: '\2022';
}
.wysiwyg-content ul[data-checked='true'],
.wysiwyg-content ul[data-checked='false'] {
  pointer-events: none;
}
.wysiwyg-content ul[data-checked='true'] > li *,
.wysiwyg-content ul[data-checked='false'] > li * {
  pointer-events: all;
}
.wysiwyg-content ul[data-checked='true'] > li::before,
.wysiwyg-content ul[data-checked='false'] > li::before {
  color: #777;
  cursor: pointer;
  pointer-events: all;
}
.wysiwyg-content ul[data-checked='true'] > li::before {
  content: '\2611';
}
.wysiwyg-content ul[data-checked='false'] > li::before {
  content: '\2610';
}
.wysiwyg-content li::before {
  display: inline-block;
  white-space: nowrap;
  width: 1.2em;
}
.wysiwyg-content li:not(.ql-direction-rtl)::before {
  margin-left: -1.5em;
  margin-right: 0.3em;
  text-align: right;
}
.wysiwyg-content li.ql-direction-rtl::before {
  margin-left: 0.3em;
  margin-right: -1.5em;
}
.wysiwyg-content ol li:not(.ql-direction-rtl),
.wysiwyg-content ul li:not(.ql-direction-rtl) {
  padding-left: 1.5em;
}
.wysiwyg-content ol li.ql-direction-rtl,
.wysiwyg-content ul li.ql-direction-rtl {
  padding-right: 1.5em;
}
.wysiwyg-content ol li {
  counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  counter-increment: list-0;
}
.wysiwyg-content ol li:before {
  content: counter(list-0, decimal) '. ';
}
.wysiwyg-content ol li.ql-indent-1 {
  counter-increment: list-1;
}
.wysiwyg-content ol li.ql-indent-1:before {
  content: counter(list-1, lower-alpha) '. ';
}
.wysiwyg-content ol li.ql-indent-1 {
  counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}
.wysiwyg-content ol li.ql-indent-2 {
  counter-increment: list-2;
}
.wysiwyg-content ol li.ql-indent-2:before {
  content: counter(list-2, lower-roman) '. ';
}
.wysiwyg-content ol li.ql-indent-2 {
  counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}
.wysiwyg-content ol li.ql-indent-3 {
  counter-increment: list-3;
}
.wysiwyg-content ol li.ql-indent-3:before {
  content: counter(list-3, decimal) '. ';
}
.wysiwyg-content ol li.ql-indent-3 {
  counter-reset: list-4 list-5 list-6 list-7 list-8 list-9;
}
.wysiwyg-content ol li.ql-indent-4 {
  counter-increment: list-4;
}
.wysiwyg-content ol li.ql-indent-4:before {
  content: counter(list-4, lower-alpha) '. ';
}
.wysiwyg-content ol li.ql-indent-4 {
  counter-reset: list-5 list-6 list-7 list-8 list-9;
}
.wysiwyg-content ol li.ql-indent-5 {
  counter-increment: list-5;
}
.wysiwyg-content ol li.ql-indent-5:before {
  content: counter(list-5, lower-roman) '. ';
}
.wysiwyg-content ol li.ql-indent-5 {
  counter-reset: list-6 list-7 list-8 list-9;
}
.wysiwyg-content ol li.ql-indent-6 {
  counter-increment: list-6;
}
.wysiwyg-content ol li.ql-indent-6:before {
  content: counter(list-6, decimal) '. ';
}
.wysiwyg-content ol li.ql-indent-6 {
  counter-reset: list-7 list-8 list-9;
}
.wysiwyg-content ol li.ql-indent-7 {
  counter-increment: list-7;
}
.wysiwyg-content ol li.ql-indent-7:before {
  content: counter(list-7, lower-alpha) '. ';
}
.wysiwyg-content ol li.ql-indent-7 {
  counter-reset: list-8 list-9;
}
.wysiwyg-content ol li.ql-indent-8 {
  counter-increment: list-8;
}
.wysiwyg-content ol li.ql-indent-8:before {
  content: counter(list-8, lower-roman) '. ';
}
.wysiwyg-content ol li.ql-indent-8 {
  counter-reset: list-9;
}
.wysiwyg-content ol li.ql-indent-9 {
  counter-increment: list-9;
}
.wysiwyg-content ol li.ql-indent-9:before {
  content: counter(list-9, decimal) '. ';
}
.wysiwyg-content .ql-indent-1:not(.ql-direction-rtl) {
  padding-left: 3em;
}
.wysiwyg-content li.ql-indent-1:not(.ql-direction-rtl) {
  padding-left: 4.5em;
}
.wysiwyg-content .ql-indent-1.ql-direction-rtl.ql-align-right {
  padding-right: 3em;
}
.wysiwyg-content li.ql-indent-1.ql-direction-rtl.ql-align-right {
  padding-right: 4.5em;
}
.wysiwyg-content .ql-indent-2:not(.ql-direction-rtl) {
  padding-left: 6em;
}
.wysiwyg-content li.ql-indent-2:not(.ql-direction-rtl) {
  padding-left: 7.5em;
}
.wysiwyg-content .ql-indent-2.ql-direction-rtl.ql-align-right {
  padding-right: 6em;
}
.wysiwyg-content li.ql-indent-2.ql-direction-rtl.ql-align-right {
  padding-right: 7.5em;
}
.wysiwyg-content .ql-indent-3:not(.ql-direction-rtl) {
  padding-left: 9em;
}
.wysiwyg-content li.ql-indent-3:not(.ql-direction-rtl) {
  padding-left: 10.5em;
}
.wysiwyg-content .ql-indent-3.ql-direction-rtl.ql-align-right {
  padding-right: 9em;
}
.wysiwyg-content li.ql-indent-3.ql-direction-rtl.ql-align-right {
  padding-right: 10.5em;
}
.wysiwyg-content .ql-indent-4:not(.ql-direction-rtl) {
  padding-left: 12em;
}
.wysiwyg-content li.ql-indent-4:not(.ql-direction-rtl) {
  padding-left: 13.5em;
}
.wysiwyg-content .ql-indent-4.ql-direction-rtl.ql-align-right {
  padding-right: 12em;
}
.wysiwyg-content li.ql-indent-4.ql-direction-rtl.ql-align-right {
  padding-right: 13.5em;
}
.wysiwyg-content .ql-indent-5:not(.ql-direction-rtl) {
  padding-left: 15em;
}
.wysiwyg-content li.ql-indent-5:not(.ql-direction-rtl) {
  padding-left: 16.5em;
}
.wysiwyg-content .ql-indent-5.ql-direction-rtl.ql-align-right {
  padding-right: 15em;
}
.wysiwyg-content li.ql-indent-5.ql-direction-rtl.ql-align-right {
  padding-right: 16.5em;
}
.wysiwyg-content .ql-indent-6:not(.ql-direction-rtl) {
  padding-left: 18em;
}
.wysiwyg-content li.ql-indent-6:not(.ql-direction-rtl) {
  padding-left: 19.5em;
}
.wysiwyg-content .ql-indent-6.ql-direction-rtl.ql-align-right {
  padding-right: 18em;
}
.wysiwyg-content li.ql-indent-6.ql-direction-rtl.ql-align-right {
  padding-right: 19.5em;
}
.wysiwyg-content .ql-indent-7:not(.ql-direction-rtl) {
  padding-left: 21em;
}
.wysiwyg-content li.ql-indent-7:not(.ql-direction-rtl) {
  padding-left: 22.5em;
}
.wysiwyg-content .ql-indent-7.ql-direction-rtl.ql-align-right {
  padding-right: 21em;
}
.wysiwyg-content li.ql-indent-7.ql-direction-rtl.ql-align-right {
  padding-right: 22.5em;
}
.wysiwyg-content .ql-indent-8:not(.ql-direction-rtl) {
  padding-left: 24em;
}
.wysiwyg-content li.ql-indent-8:not(.ql-direction-rtl) {
  padding-left: 25.5em;
}
.wysiwyg-content .ql-indent-8.ql-direction-rtl.ql-align-right {
  padding-right: 24em;
}
.wysiwyg-content li.ql-indent-8.ql-direction-rtl.ql-align-right {
  padding-right: 25.5em;
}
.wysiwyg-content .ql-indent-9:not(.ql-direction-rtl) {
  padding-left: 27em;
}
.wysiwyg-content li.ql-indent-9:not(.ql-direction-rtl) {
  padding-left: 28.5em;
}
.wysiwyg-content .ql-indent-9.ql-direction-rtl.ql-align-right {
  padding-right: 27em;
}
.wysiwyg-content li.ql-indent-9.ql-direction-rtl.ql-align-right {
  padding-right: 28.5em;
}

.wysiwyg-content img:not([width]) {
  width: 100%;
}

.wysiwyg-content img[width] {
  display: inline;
  margin: 0px 1em 1em 0px;
  float: left;
}
